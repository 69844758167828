import React from "react";
import { Image } from "react-bootstrap";

const BannerImage = ({ imageUrl }) => {
  return (
    <Image style={{ borderRadius: "8px" }} src={imageUrl.default_image} fluid />
  );
};

export default BannerImage;
